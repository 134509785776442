import { MenuItem as MenuItemType } from '@/lib/model';
import { cn } from '@/lib/utils';
import { MenuItemLink } from './MenuItemLink';
import { Fragment, ReactNode } from 'react';
import { LocaleUppercase } from '../../text/LocaleUppercase';

export type MenuItemExtendedType = MenuItemType & {
  title?: string;
};

type MenuItemVariant = 'primary' | 'secondary';

interface Props {
  menuItems: MenuItemExtendedType[];
  className?: string;
  titleClassName?: string;
  variant?: MenuItemVariant;
}

export const SubpageMenuItem = ({
  menuItems,
  className,
  variant,
  titleClassName,
}: Props) => {
  return (
    <Fragment>
      {
        menuItems?.map((item, index) => {
          return (
            <MenuItemLink menu={item} key={item.id}>
              <span className="flex justify-center items-center gap-3">
                {index > 0 && variant === 'secondary' && (
                  <span className="w-1 h-1 rounded-full  bg-grey-7 my-3.5"></span>
                )}
                <span
                  className={cn(
                    `font-notosans text-sm text-grey-10 leading-8 article-hover-effect`,
                    className,
                  )}
                >
                  <span
                    className={cn(
                      'opacity-85 whitespace-nowrap',
                      titleClassName,
                    )}
                  >
                    <LocaleUppercase>
                      {item.name || (item.title as string)}
                    </LocaleUppercase>
                  </span>
                </span>
              </span>
            </MenuItemLink>
          );
        }) as ReactNode
      }
    </Fragment>
  );
};
