import { TRT_STICKY_HEADER } from '../constant';
import { cn } from '@/lib/utils';
import { MenuStateProvider } from './components/MenuStateProvider';
import { SecondRowMenuWrapper } from '../second-row-menu-wrapper/SecondRowMenuWrapper';
import { HamburgerIcons } from './components/HamburgerIcons';
import { HeaderMenuWrapper } from './components/HeaderMenuWrapper';
import { MenuSidebar } from './components/MenuSidebar';
import { Menu } from '@/lib/model';
import { LogoVariant } from '@/utils/setChannelColor';
import { PageTitleWrapper } from './components/PageTitleWrapper';
import { ComponentProps, ReactNode } from 'react';

export interface MainStickyHeaderProps
  extends Pick<
    ComponentProps<typeof PageTitleWrapper>,
    'pageTitle' | 'titleClassName' | 'parentTitle'
  > {
  menu?: Menu;
  sideMenu?: Menu;
  secondaryMenu?: Menu;
  isSecondary?: boolean;
  stickyClassName?: string;
  logoVariant?: LogoVariant;
  hamburgerMenuWrapperClassName?: string;
  languageSelectionWrapperClassName?: string;
  ChannelLogo: ReactNode;
}

export const MainStickyHeader = ({
  menu,
  sideMenu,
  pageTitle,
  parentTitle,
  titleClassName,
  languageSelectionWrapperClassName,
  hamburgerMenuWrapperClassName,
  isSecondary,
  stickyClassName,
  logoVariant,
  ChannelLogo,
}: MainStickyHeaderProps) => {
  return (
    <div
      id={TRT_STICKY_HEADER}
      className={cn(
        'sticky top-0 bg-grey-2 z-[11] w-full items-center flex flex-row border-y border-y-[#E9E9E9]',
        `2xl:h-header2xlH lg:h-headerLgH h-headerDefaultH`,
        stickyClassName,
      )}
    >
      <MenuStateProvider>
        {/* Hamburger Menu Title */}
        <HamburgerIcons
          pageTitle={pageTitle}
          parentTitle={parentTitle}
          wrapperClassName={hamburgerMenuWrapperClassName}
          ChannelLogo={ChannelLogo}
        />

        <MenuSidebar menu={menu as Menu} sideMenu={sideMenu as Menu} />

        {/* Menu Items, Home,Search blabla... Desktop*/}
        {isSecondary ? (
          <SecondRowMenuWrapper
            menu={menu}
            withMarginBottom={false}
            className="block !top-0 border-none"
          />
        ) : (
          <HeaderMenuWrapper
            pageTitle={pageTitle}
            parentTitle={parentTitle}
            languageSelectionWrapperClassName={
              languageSelectionWrapperClassName
            }
            menu={menu}
            titleClassName={titleClassName}
            iconColor={logoVariant === 'light' ? 'text-grey-2' : undefined}
          />
        )}
      </MenuStateProvider>
    </div>
  );
};
