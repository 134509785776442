'use client';

import { LogoVariant } from '@/utils/setChannelColor';
import { GlobalHeader } from '../GlobalHeader';
import { MainStickyHeader } from '../MainStickyHeader';
import { ChannelLogoClientside } from '@/app/components/channel-logo/client';
import { MainHeaderSidebarProps } from '../type';

export function MainHeaderSidebarClient({
  topLogoContainerClassName,
  logoVariant = LogoVariant.Dark,
  ...rest
}: MainHeaderSidebarProps) {
  return (
    <>
      <GlobalHeader
        topLogoContainerClassName={topLogoContainerClassName}
        logoVariant={logoVariant}
      />
      <MainStickyHeader
        {...rest}
        logoVariant={logoVariant}
        ChannelLogo={
          <ChannelLogoClientside
            className="text-white w-auto h-full flex items-center"
            logoVariant={LogoVariant.Light}
          />
        }
      />
    </>
  );
}
