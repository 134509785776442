import DarkLogoHeader from '~/public/svg/header-logo.svg';
import LightLogoHeader from '~/public/svg/header-white-logo.svg';
import { cn } from '@/lib/utils';
import { TRT_STICKY_HEADER_WRAPPER } from '../constant';
import Image from 'next/image';
import { LinkWithChannelLink } from '../../link/LinkWithChannel';
import { FEATURE_FLAG } from '@/feaureflag';
import Link from 'next/link';
import { SearchIcon } from '../../icons';
import { LogoVariant } from '@/utils/setChannelColor';

export interface GlobalHeaderProps {
  logoVariant?: LogoVariant;
  topLogoContainerClassName?: string;
}
export const GlobalHeader = ({
  topLogoContainerClassName,
  logoVariant,
}: GlobalHeaderProps) => {
  return (
    <div
      id={TRT_STICKY_HEADER_WRAPPER}
      className={cn(
        'sm:ps-0 ps-5 w-full flex justify-between items-center xl:h-globalTopHeaderXlH lg:h-globalTopHeaderLgH h-globalTopHeaderH bg-grey-2',
        topLogoContainerClassName,
      )}
    >
      <LinkWithChannelLink
        href={'/'}
        className="w-full ml-0 md:mx-auto md:w-fit"
      >
        <Image
          src={logoVariant === 'dark' ? DarkLogoHeader : LightLogoHeader}
          width={0}
          height={0}
          sizes="100vw"
          alt="logo"
          className="w-fit sm:w-full text-white xl:h-[29px] lg:h-[22px] md:h-[19px] h-[16px]"
        />
      </LinkWithChannelLink>
      {FEATURE_FLAG.aiSearch ? (
        <Link
          dir="inherit"
          href="/ai-search"
          className={cn(
            'cursor-pointer 2xl:end-[57px] me-5 sm:hidden flex items-center justify-center',
          )}
        >
          <SearchIcon
            className={logoVariant === 'dark' ? 'text-grey-11' : 'text-white'}
          />
        </Link>
      ) : null}
    </div>
  );
};
